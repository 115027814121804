.btn-file {
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    margin-right: 5px;

    > input {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        .opacity(0);
        font-size: @font-size-large;
        height: 100%;
        width: 100%;
        direction: ltr;
        cursor: pointer;
    }
}
.fileinput {
  margin-bottom: 10px;
  display: inline-block;
  .form-control {
    padding-top: 7px;
    padding-bottom: 5px;
    display: inline-block;
    margin-bottom: 0px;
    vertical-align: middle;
    cursor: text;
  }
  .btn-wrap {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .btn {
    // .btn-sm;
    font-weight: 500;
    font-size: @font-size-small;
    float: none !important;
    display: block;
    margin: 10px 0;
  }

  .thumbnail {
    overflow: hidden;
    display: inline-block;
    margin-bottom: 5px;
    vertical-align: middle;
    text-align: center;
    width: 90px;
    height: 90px;
    margin-right: 10px;
     > img {
      height: 80px;
      width: 80px;
      // object-fit: cover;
    }
  }
  .fileinput .btn {
    vertical-align: middle;
  }
}
.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}
.fileinput-inline .fileinput-controls {
  display: inline;
}
.fileinput-filename {
  vertical-align: middle;
  display: inline-block;
  overflow: hidden;
  height: 20px;
}
.form-control .fileinput-filename {
  vertical-align: bottom;
}
.fileinput.input-group {
  display: table;
  > * {
    position: relative;
    z-index: 2;
  }
    > .btn-file {
    z-index: 1;
  }
}

.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 @border-radius-small @border-radius-small 0;
}

.form-group.has-error .fileinput .fileinput-preview {
  color: @brand-danger;
}
.form-group.has-error .fileinput .thumbnail {
  border-color: @brand-danger;
}
.form-group.has-success .fileinput .fileinput-preview {
  color: @brand-success;
}
.form-group.has-success .fileinput .thumbnail {
  border-color: @brand-success;
}
.input-group-addon:not(:first-child) {
  border-left: 0;
}
.fileinput-avatar .thumbnail {
    border-radius: 50%;
}
.fileinput-avatar img {
    .img-circle;
    max-width: 100px;
    width: 100px;
    max-height: 100px;
    height: 100px;
}
