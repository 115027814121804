.ratings {
    .row;
}
.rating {
    .make-xs-column(2);
    text-align: center;
    position: static;
}
.rating .ratingWrap {
    position: relative;
}

.rating .text-warning {
    color: @brand-warning;
}

.rating .rating-description {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translate(60%, 0);
    font-size: 2em;
    color: @gray;
}

.ratingTotal {
    .well;
}

.ratingTotal .fa,
.ratingTotal span {
    font-size: 4em;
    width: 49%;
    display: inline-block;
}
.ratingTotal span {
    text-align: right;
    color: @gray;
}

.ratingProgress {
    padding-left: @line-height-computed * 2;
    position: relative;
}
.ratingProgress .fa {
    position: absolute;
    font-size: 2em;
    line-height: 40px;
    left: 0;
}
.ratingProgress span {
    position: absolute;
    color: @gray;
    font-size: @font-size-large;
    right: @line-height-computed / 2;
    line-height: @line-height-computed * 2;
}
.ratingGood {
    text-align: center;
}
.ratingGood .ratingGood--percentage {

}