.avatar {
    .img-circle;
}
.avatar--has-warning {
    position: relative;
}
.avatar--has-warning:before,
.avatar--has-warning:after {
    display: inline-block;
    font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
    font-size: 16px;
    speak: none;
    text-rendering: auto;
    position: absolute;
    bottom: -4px;
    right: -4px;

    // Better Font Rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.avatar--has-warning:before {
    content: "\f26d";
    color: #fff;
    font-size: 19px;
    bottom: -5px;
    right: -5px;
}
.avatar--has-warning:after {
    content: "\f1f1";
    color: @brand-danger;
}
.icon-avatar {
    background: @gray-light;
    color: #fff;
    border-radius: 50%;
    line-height: 52px;
    height: 40px;
    width: 40px;
    position: relative;
    .text-center;
    i {
        font-size: 30px;
    }
    &.doubleline {
        line-height: @font-size-small;
        padding-top: 8px;
        font-size: @font-size-small;
        -webkit-font-smoothing: subpixel-antialiased;
    }
    .modal-title & {
        background: lighten(@gray, 40%);
        float: left;
        margin-right: @table-cell-padding * 2;
    }
}
.icon-avatar.icon-avatar--sm {
    width: 32px;
    height: 32px;
    vertical-align: middle;
    display: inline-block;
}
.icon-avatar.icon-avatar--sm i {
    position: absolute;
    text-align: center;
    width: 100%;
    left: 0;
    font-size: 22px;
    line-height: 34px;
    display: inline-block;
}