#content {
    padding: 0 0 @grid-gutter-width;
    @media (min-width: @screen-sm-min) {
        padding-top: @grid-gutter-width;
    }
    @media (min-width: @screen-lg) {
        padding-top: 130px !important;
        padding-left: @navbar-width;
    }
    >.container {
        @media (max-width: @screen-xs-max) {
            padding: 0;
        }
    }
}
.container {
    max-width: @screen-lg;
    @media (min-width: @screen-sm-min) {
        width: calc(~'100% - 30px');
    }
}

#main-header {
    background: @brand-primary;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    .clearfix;
    padding: 12px 0;
    z-index: 25;
    .transition(padding-left .3s ease-in-out);

    @media (min-width: @screen-lg) {
        position: fixed;
        top: @navbar-height !important;
        width: 100%;
        padding-left: @navbar-width;
        // left: @navbar-width !important;
    }

    .container {
        padding-right: 10px;
        position: relative;
    }
    h1 {
        display: inline-block;
        font-weight: 400;
        color: #fff;
        margin: 0;
        line-height: @line-height-computed * 2;
        white-space: nowrap;
        overflow: hidden;
        height: @line-height-computed * 2;
        text-overflow: ellipsis;
        vertical-align: top;
        width: calc(~'100% - 45px');
        position: relative;
        z-index: 10;
        @media (min-width: @screen-lg) {
            width: calc(~'100% - 30px');
        }
        a {
            color: #fff;
            .transition(all .3s ease-in-out);

            &:hover {
                box-shadow: inset 0 -2px 0 rgba(255, 255, 255, .5);
            }

            &:not(:first-child) {
                margin-left: 30px;
            }

            &:not(:first-child):before {
                .icon;
                position: absolute;
                content: "\f2fb";
                font-size: 24px;
                height: @grid-gutter-width;
                width: 24px;
                position: relative;
                top: 3px;
                margin-left: -30px;
                margin-right: 5px;
            }
        }
    }
    .chapter {
        display: inline-block;
        height: @line-height-computed * 2;
        &:before {
            .icon;
            content: "\f2fb";
            font-size: 24px;
            height: @grid-gutter-width;
            width: 24px;
            position: relative;
            top: 3px;
            margin-right: 5px;
            text-align: center;
        }
    }

    .btn-toolbar {
        .pull-right;
        position: relative;
        z-index: 20;
    }
    .btn {
        .btn-success;
    }
}
#main-header.affix {
    position: fixed;
    top: 0;
    z-index: 25;
    width: 100%;
}
#menu-trigger {
    color: #fff;
    display: inline-block;
    line-height: @line-height-computed * 2;
    vertical-align: top;
    width: 45px;
    margin-left: -10px;
    .text-center;
    .opacity(.75);
    .transition(opacity .3s ease-in-out);
    @media (min-width: @screen-sm-min) {
        width: 60px;
        margin-left: -20px;
    }

    &:hover, &:focus {
        .opacity(1);
    }
    &:focus {
        i {
            .rgba-black(.1);
        }
    }
    @media (min-width: @screen-lg) {
        .opacity(0);
        width: 0;
        overflow: hidden;
        margin: 0;
    }
    i {
        border-radius: 50%;
        font-size: 23px;
        line-height: 30px;
        height: 30px;
        width: 30px;
        vertical-align: middle;
        -webkit-font-smoothing: none;
    }
}


.header-actions {
    .pull-right;
    height: 40px;
    margin-top: -40px;
    i {
        .transition(all .3s ease-in-out);
        border-radius: 50%;
        line-height: 30px;
        font-size: 20px;
        .text-center;
        height: 30px;
        width: 30px;
    }
    > a,
    .header-action {
        cursor: pointer;
        display: inline-block;
        color: #fff;
        padding: 5px 0;
        position: relative;
        z-index: 15;
        &:hover, &:focus {
            i {
                .rgba-black(.1);
            }
        }
    }
}

.table-search {
    &:hover {
        input {
            // .rgba-black(.1);
            box-shadow: inset 30px 30px 0 rgba(0,0,0,.1);
        }
    }
    &:before {
        border-radius: 50%;
        color: #fff;
        .icon;
        content: "\f05f";
        font-size: @font-size-h1;
        line-height: 30px;
        height: 30px;
        width: 30px;
        .text-center;
        vertical-align: middle;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 90;
    }


    input {
        background: transparent;
        border: 0;
        border-radius: 15px;
        color: #fff;
        cursor: pointer;
        height: 30px;
        position: relative;
        z-index: 100;
        .transition(width .3s ease-in-out);
        width: 30px;
        padding-left: 30px;
        // .opacity(0);
        &:focus {
            // .rgba-black(.1);
            background: @brand-primary;
            box-shadow: inset 30px 30px 0 rgba(0,0,0,.1);

            cursor: text;
            width: 100%;
            .opacity(1);
        }
    }
}