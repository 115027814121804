.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeOutDown {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100% , 0);
    }
}
@keyframes fadeOutDown {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translate3d(0, 100% , 0);
    }
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.alert {
    border: 0;

    &.fixed {
        @media (max-width: @screen-xs-max) {
            bottom: 0;
            left: 0;
            border-radius: 0;
            width: 100%;
            z-index: 10001;
        }
    }
    &.alert-popup {
        .animated;
        background: @gray-darker;
        color: #fff;
        opacity: 0;
    }
    .close {
        .transition(all .3s ease-in-out);
        text-shadow: none;
        font-weight: 400;
        top: 10px;
        right: 10px;
        border-radius: 50%;
        font-size: 20px;
        display: block;
        text-align: center;
        height: 30px;
        width: 30px;
        position: absolute;
        color: #fff;
        .opacity(.75);

        &:hover, &:focus {
            color: #fff;
            .opacity(1);
            .rgba-black(.1);
        }
  }

}
.alert-dismissable, .alert-dismissible {
  padding-right: 50px;
}

.alert-danger, .alert-success {
  position: relative;
  padding-left: 40px;
  &:before {
    .icon;
    content: "\f269";
    font-size: 20px;
    vertical-align: middle;
    left: 15px;
    position: absolute;
  }
}
.alert-success {
  &:before {
    content: "\f269";
    color: @brand-success;
  }
}
.alert-danger {
  &:before {
    content: "\f1f1";
    color: #fff;
  }
}

