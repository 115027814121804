// Non-modular css sucks, ik moet nu deze klasse overschrijven :/
.datepicker {
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);

    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;

    opacity: 1;
    z-index: 9999 !important;
}