.table-responsive  {
    border: 0;
    margin: 0;
    overflow: visible !important; // Dient voor dropdown, anders wordt die afgekapt… so much for responsiveness?!
}
.table {
    background: transparent;
    .table-hover;
    display: table;
    margin: 0;
    width: 100%;
}
.table>thead>tr>th {
     color: @gray;
     border-color: @table-border-color;
     border-width: 1px;
}
.table>tbody >tr>td, .table .td {
    vertical-align: top;
}
.table>thead>tr>th {
    padding-bottom: 16px;
    padding-top: 16px;
}

.table>thead>tr>th:first-child,
.table>tbody>tr>th:first-child,
.table>tfoot>tr>th:first-child,
.table>thead>tr>td:first-child,
.table>tbody>tr>td:first-child,
.table>tfoot>tr>td:first-child,
.table>.tr .td:first-child {
    padding-left: 16px;
}
.tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    &:hover {
        background-color: @table-bg-hover;
    }
}

tr, .tr {
    cursor: pointer;

    .actions {
        position: absolute;
        right: 40px;
        .opacity(0);
    }
    &:hover {
        .actions {
            .opacity(1);
        }
    }
}

.td {
    border-bottom: 1px solid @table-border-color;
    padding: @table-cell-padding;
    display: table-cell;
    .table:last-child & {
        border: 0;
    }
}

td, .td {
    .avatar {
      .img-circle;
    }
    .actions, .dropdown {
        min-height: 40px;
        font-size: 0;
        display: inline-block;
    }
    .tooltip {
        white-space: nowrap;
    }
    strong {
        a {
            color: @gray-dark;
            font-weight: 500;
            &:hover {
                color: @gray-dark;
            }
        }
    }
}

.cell-avatar {
    width: 40px;
    white-space: nowrap;
}
.cell-text {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.singleline {
        vertical-align: middle;
    }
}
.cell-actions {
    width: 40px;
    text-align: right;
    white-space: nowrap;
}
.icon-avatar {
    background: @gray-light;
    color: #fff;
    border-radius: 50%;
    line-height: 40px;
    height: 40px;
    width: 40px;
    .text-center;
    .md {
        font-size: 30px;
    }
    &.doubleline {
        line-height: @font-size-small;
        padding-top: 8px;
        font-size: @font-size-small;
        -webkit-font-smoothing: subpixel-antialiased;
    }

}
.row--inactive,
.row--inactive a {
    color: @gray-light;
}
.row--inactive {
    img {
        filter: gray;
        filter: grayscale(100%);
        .opacity(.5);
    }
}

.datenav {
    font-size: @font-size-h1;
    margin: 5px 0;
    @media (min-width: @screen-sm-min) {
        margin: -(@grid-gutter-width / 2) 0 @grid-gutter-width / 2;
    }
    a {
        color: @gray;
        display: inline-block;
        padding: 0 10px;
        &:hover {
            color: @brand-primary;
        }
    }
}
